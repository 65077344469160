<template lang="pug">
  .datasets-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.uo-header
      h1.nio-h1.text-primary-darker Datasets
    .no-datasets(
      v-if="datasets && datasets.length === 0"
    )
      NioIconFramer(
        icon-name="display-list"
      )
      h3.nio-h3.text-primary-darker You have no datasets
      NioButton(
        normal-secondary
        @click="seeHow"
      ) Learn about datasets
    .datasets(
      v-else
    )
      NioSlatTable(
        v-if="columns && datasets"
        :items="datasets"
        :columns="columns"
        :search-sort-header="true"
        :footer-actions="datasets.length === 1"
        :sort-options="sortOptions"
        :initial-items-per-page="100"
        :searchable-props="['display_name', 'id']"
        pagination 
        @itemClicked="handleItemClicked($event)"
        action="link"
      )   
</template>

<script>

import { sortOptions } from './sortOptions'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import moment from 'moment'

export default {
  data: () => ({
    datasets: null,
    loading: true,
    columns: null,
    sortOptions: sortOptions,
  }),
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.getDatasets()
    },
    seeHow() {
      window.open('https://kb.narrative.io/dataset-manager', '_blank')
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      }
      return str
    },
    formatDate(date) {
      return `${date.getFullYear()}`
    },
    async getDatasets() {
      try {
        const res = await this.$nioOpenApi.get('/datasets')
        this.datasets = res.data.records
        this.makeColumns()
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            title: 'display_name',
            label: 'Name',
            subtitle: this.computeDesc
          }
        },
        {
          name: "id",
          label: "Dataset ID",
          computed: this.computeId
        },
        {
          name: "created",
          label: "Created",
          computed: this.computeCreated
        },
        {
          name: "lastIngestion",
          label: "Last Ingestion Date",
          computed: this.computeLastIngestionDate
        }
      ]
    },
    computeId(item) {
      return `#${item.id}`
    },
    computeDesc(item) {
      return item.description ? this.truncateString(item.description, 85) : ''
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    computeLastIngestionDate(item) {
      if (item.last_snapshot_created_at) {
        return `${moment(item.last_snapshot_created_at).format('MMM D, YYYY HH:mm')}`
      }
      return 'N/A'
    },
    handleItemClicked(event) {
      parent.postMessage({
        name: 'pageNavigation',
        payload: `datasets/${event.id}`
      },"*")
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.datasets-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 1.5rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  ::v-deep .image-title-subtitle-slot
    max-width: 30rem
    overflow: hidden
    .nio-slat-title, .nio-slat-subtitle
      white-space: normal !important
      word-break: break-all
    max-width: 30rem
  .no-datasets, .free-version-footer
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
</style>