export const sortOptions = [
	{
		label: 'Date [newest - oldest]',
		value: {
			itemProp: 'created_at',
			propType: 'Date',
			order: 'descending'
		}	
  },
  {
		label: 'Date [oldest - newest]',
		value: {
			itemProp: 'created_at',
			propType: 'Date',
			order: 'ascending'
		}
	},
  {
		label: 'Last Ingestion Date [newest - oldest]',
		value: {
			itemProp: 'computed_last_ingestion',
			propType: 'Date',
			order: 'descending'
		}	
  },
  {
		label: 'Last Ingestion Date [oldest - newest]',
		value: {
			itemProp: 'computed_last_ingestion',
			propType: 'Date',
			order: 'ascending'
		}
	},
  {
    label: 'Alphabetic [A-Z]',
    value: {
			itemProp: 'name',
			propType: 'String',
			order: 'ascending'
		}	
  },
  {
		label: 'Alphabetic [Z-A]',
		value: {
			itemProp: 'name',
			propType: 'String',
			order: 'descending'
		}	
  },
	{
		label: 'ID (lowest first)',
		value: {
			itemProp: 'id',
			propType: 'Number',
			order: 'ascending'
		}	
  },
  {
		label: 'ID (highest first)',
		value: {
			itemProp: 'id',
			propType: 'Number',
			order: 'descending'
		}
	}
]